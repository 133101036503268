<template>
  <div
      class="o-fat-list mt-3"
      v-bind:class="{
      'o-fat-list--table': this.selectTypeLine,
    }"
  >
    <!--
    <b-alert v-if="fatture.length>0" show variant="warning" class="text-center mb-5 col-10 offset-1 p-5" dismissible>
      <p>Se non risulta possibile visualizzare il to o la fattura scaricata assicurarsi di consentire al browser sempre l'uso dei popup cliccando sulla seguente icona nella barra dell'indirizzo:</p>
      <b-img fluid center src="../../assets/blocco-popup.png" alt="Blocco popup"></b-img>
    </b-alert>  
    -->

    <div class="o-fat-list__head" v-if="selectTypeLine === true">
      <div v-if="!isPay" class="o-fat-list__inner">
        <div class="o-fat-list--send">
          {{ $t("fatture.tab_-1") }}
        </div>        
        <div  class="o-fat-list--data">
          {{ $t("fatture.tab_0") }}
        </div>
        <div class="o-fat-list--type">
          {{ $t("fatture.tab_1") }}
        </div>
        <div class="o-fat-list--name">
          {{ $t("fatture.tab_2") }}
        </div>
        <div class="o-fat-list--name">
          {{ $t("fatture.tab_stato") }}
        </div>        
      </div>
      <div v-if="isPay" class="o-fat-list__full">
        <div class="o-fat-list--data">
          {{ $t("fatture.tab_0") }}
        </div>
        <div class="o-fat-list--type">
          {{ $t("fatture.tab_1") }}
        </div>
        <div class="o-fat-list--name">
          {{ $t("fatture.tab_2") }}
        </div>

        <div  class="o-fat-list--name">
          {{ $t("fatture.tab_imp") }}
        </div>
      </div>

      <div v-if="(!this.isPay)||this.showDownload" class="o-fat-list__action">
        <div class="o-fat-list--download">
          {{ $t("fatture.tab_3") }}
        </div>
        <div v-if="false" class="o-fat-list--send">
          {{ $t("fatture.tab_5") }}
        </div>
        <div class="o-fat-list--send" v-if="false">
          {{ $t("fatture.tab_-1") }}
        </div>        
      </div>
    </div>
    <app-fat-card v-bind:key="fatt" v-for=" fatt in fatture"
                  link_download="Large"
                  link_share=""
                  :link_eye="''"
                  labelType="FT"
                  :name="fatt.NumeroDocumento"
                  :typeLine="selectTypeLine"
                  :isActive="!fatt.Pagata"
                  :paying="!fatt.Pagata && fatt.Pagando"
                  tipo-file="PDF"
                  :is-pay="isPay"
                  :object="fatt"
                  :importo="fatt.ImportoDocumento"
                  :data_fattura="fatt.DataDocumento"
                  :show-download="showDownload"
                  @openRefert="pdfOnNewWindow(fatt.info)"
                  @pagaFattura="payInvoice(fatt)"
    ></app-fat-card>
    <!--<app-fat-card
      link_eye="Large"
      link_share="Large"
      labelType="Poliambulatorio"
      name="Visita specialistica neurologica"
      :typeLine="selectTypeLine"
      :isActive="false"
      @openRefert="ModalViewRefert(12)"
    ></app-fat-card>
    <app-fat-card
      link_download="Large"
      link_share="Large"
      labelType="Poliambulatorio"
      name="Esami di laboratorio"
      :typeLine="selectTypeLine"
      :isActive="false"
      @openRefert="ModalViewRefert(13)"
    ></app-fat-card>-->

    <div class="col-xl-6 offset-xl-3 col-10 offset-1 text-center mb-3" v-if="!this.isPay">
      <b-button variant="primary"
                class="text-uppercase mt-2 ml-xl-2"
                @click="payInvoices()"
                v-if="toPay()">
      {{ $t("generic.paga_selected") }}
      </b-button>
    </div>

    <b-modal
        id="download_referti"
        centered
        modal-class="o-modal"
        content-class="o-modal__content"
        header-class="o-modal__header"
        dialog-class="o-modal__dialog"
        body-class="o-modal__body o-modal__body--response "
        ok-title="ok-title"
        :cancel-disabled="true"
        :hide-footer="true"
        :hide-header-close="true"
        :hide-header="true"
        size="lg"
    >
      <div class="o-modal__body--icon">
        <span class=" icon-download"></span>
      </div>
      <p class="o-modal__body--title text-success">
        <strong> Referto disponibile </strong>
      </p>
      <p>Il referto è pronto per essere visualizzato e scaricato</p>
      <b-button variant="primary" size="" class="text-light text-uppercase mt-3"
      >SCARICA REFERTO
      </b-button
      >
    </b-modal>

    <b-modal
        id="referto-nondisponibile"
        centered
        modal-class="o-modal"
        content-class="o-modal__content"
        header-class="o-modal__header"
        dialog-class="o-modal__dialog"
        body-class="o-modal__body o-modal__body--response "
        ok-title="ok-title"
        :cancel-disabled="true"
        :hide-footer="true"
        :hide-header-close="true"
        :hide-header="true"
        size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-download"></span>
        <span class="icon-close text-light"></span>
      </div>
      <p class="o-modal__body--title text-danger">
        <strong> Referto non scaricabile </strong>
      </p>
      <p>
        <strong
        >Il referto non può essere scaricato perché contiene diversi allegati.
          E’ necessario ritirarlo di persona presso una delle sedi CDI</strong
        >
      </p>
      <p>Il referto è pronto per essere visualizzato e scaricato</p>
      <!--  <b-button variant="primary" size="" class="text-light text-uppercase mt-3"
        >SCARICA REFERTO</b-button
      > -->
    </b-modal>

    <b-modal
        id="referto-scaduto"
        centered
        modal-class="o-modal"
        content-class="o-modal__content"
        header-class="o-modal__header"
        dialog-class="o-modal__dialog"
        body-class="o-modal__body o-modal__body--response "
        ok-title="ok-title"
        :cancel-disabled="true"
        :hide-footer="true"
        :hide-header-close="true"
        :hide-header="true"
        size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-download"></span>
        <span class="icon-close text-light bg-danger"></span>
      </div>
      <p class="o-modal__body--title text-danger">
        <strong> Referto non scaricabile </strong>
      </p>
      <p>
        <strong
        >Il referto non può essere scaricato perché contiene diversi allegati.
          E’ necessario ritirarlo di persona presso una delle sedi CDI</strong
        >
      </p>
      <p>Il referto è pronto per essere visualizzato e scaricato</p>
      <!--  <b-button variant="primary" size="" class="text-light text-uppercase mt-3"
        >SCARICA REFERTO</b-button
      > -->
    </b-modal>

    <b-modal
      id="noFatt"
      centered
      v-model="noFatt"
      modal-class="o-modal"
      content-class="o-modal__content"
      header-class="o-modal__header"
      dialog-class="o-modal__dialog"
      body-class="o-modal__body o-modal__body--response "
      cancel-disabled="true"
      hide-footer="true"
      size="lg"
    >
      <p class="mt-3">{{ $t("fatture.no_file") }}</p>

      <b-button class="mt-3 text-uppercase" variant="primary" @click="$bvModal.hide('noFatt')">{{ $t("generic.ok") }}</b-button>             
    </b-modal>    
  </div>
</template>

<script>
import AppFatCard from "../molecules/AppFatCard.vue";
import apiAxios from "@/libs/apiAxios";
import router from "@/router";

export default {
  components: {AppFatCard},
  name: "AppFatList",
  props: {
    userData: {default: null}, selectTypeLine: {
      default: false
    },
    fatture: {default: []},
    isPay: {default: false},
    showDownload:{default: false}
  },

  data() {
    return {
      showPay: false,
      multiple: true,
      noFatt: false,
    };
  },

  created() {
    //this.ModalViewRefert(25);
  },

  watch: {
    showPayWatch: {
      handler() {
        this.showPayHandler();
      },
      deep: true,
    },
  },
  computed: {
    actFatture: function () {

      return this.fatture;
    },
  },

  methods: {

    toPay() {
      for(let i=0;i<this.fatture.length;i++)
      {
        const fatt=this.fatture[i];
        if (!fatt.Pagata)
        {
          return true;
        }
      }

      return false;
    },

    showPayHandler(){
      if (this.fatture==null)
      {
        this.showPay=false;
        return ;
      }

      for(let i=0;i<this.fatture.length;i++)
      {
        const fatt=this.fatture[i];
        if (fatt.selected)
        {
          this.showPay=true;
          return;
        }
      }
      this.showPay=false;
    },

    payInvoices(){
      let societa = null;
      let out=[];
      for (let i=0;i<this.fatture.length;i++)
      {
        const fatt=this.fatture[i];

        if (fatt.Pagata==false && fatt.selected)
        {
          if (societa==null)
          {
            societa=fatt.Societa;
          }
          let actSoc=fatt.Societa;
          if (actSoc!=societa)
          {
              continue;
          }

          out.push(fatt);
        }
      }
      if (out.length>0)
      {
        const inv= {
          selectable : false,
          source : 'fatture',
          list : btoa(JSON.stringify(out))
        }
        sessionStorage.setItem("invoices",JSON.stringify(inv));
        router.push("fattureDaPagare");
      }

    },

    ModalViewRefert($id) {
      console.log($id);
      this.$bvModal.show("referto-nondisponibile");
    },

    pdfOnNewWindow($id) {
      let n_doc = JSON.parse(atob($id)).NumeroDocumento;

      apiAxios("jit/fatture/" + encodeURI($id) + '/download')
          .then(response => {
            if (response.data == -31) {
              console.log("No data for file");
              return;
            }

            const data =response.data.DownloadFatturaResult;
            if (!data.success)
            {
              this.noFatt = true;
              //alert("Errore:"+data.error)
              return;
            }
            const sliceSize = 512;
            const byteCharacters = atob(data.data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }


            const file = new Blob(
                byteArrays,
                {type: 'application/pdf'});
            //Build a URL from the file
            //const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            //window.open(fileURL);
            //location.href = fileURL;
            this.downloadFile(file, "Fattura_"+n_doc+".pdf");
          });


    },

    downloadFile(blob, fileName){
      const link = document.createElement('a');
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },    
    
    payInvoice(fatt) {
      console.log(fatt);

      let out=[];

      if (fatt.Pagata==false)
      {
        out.push(fatt);
      }

      if (out.length>0)
      {
        const inv= {
          selectable : false,
          source : 'fatture',
          list : btoa(JSON.stringify(out))
        }
        sessionStorage.setItem("invoices",JSON.stringify(inv));
        router.push("fattureDaPagare");
      }      
    }
  },
};
</script>
