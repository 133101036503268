<template>
  <div class="m-fat-card" v-bind:class="{ 'm-fat-card--line': this.typeLine }">
    <div
      class="m-fat-card__container"
      v-bind:class="{ 'm-fat-card__container--active': isActive }"
    >

      <div class="m-fat-card__inner" v-bind:class="{'m-fat-card__full':this.isPay}">
        <div class="m-fat-card__action--share" v-if="!this.isPay ">

          <b-form-checkbox
              v-if="this.isActive && !this.paying"
              :id="'checkbox_'+name"
              v-model="object.selected"

              :name="'checkbox_'+name"
              value="true"
              unchecked-value="false"

              class="mt-3"
          ></b-form-checkbox>
          <p class="mt-2 text-light" v-else>&nbsp;</p>
        </div>        
        <div class="m-fat-card__text--type">
          {{this.tipoDocumento}}
        </div>
        <div class="m-fat-card__icon-container">
          <div v-if="this.tipoFile == 'PDF'">
            <span class="icon-adobe"></span>
          </div>
          <div class="m-fat-card__icon-container--bg" v-else>
            <div>
              <span class="icon-ecografia text-primary"></span>
            </div>
          </div>
        </div>
        <div class="m-fat-card__data">
          {{ this.dataFormatted }}
        </div>
        <div class="m-fat-card__text--name">
          {{ this.name }}
        </div>
        <div v-if="isPay" class="m-fat-card__text--name">
          {{this.importo | toCurrency}}
        </div>
        <div v-if="!isPay" class="m-fat-card__text--name">
          <span :class="(this.isActive ? (this.paying?'text-default':'text-warning') : 'text-success')">{{ (this.isActive ? (this.paying?'Pagamento in corso':'Da pagare') : 'Pagata') }}</span>
        </div>        
      </div>
      <div class="m-fat-card__action" v-if="this.typeLine === false && (!this.isPay || this.showDownload)">
        <app-circle-icon
          v-if="this.link_eye !== ''"
          variant="medium"
          type="Hollow"
          icon="icon-eye"
          typeButton="true"
          :link="downloadRef"
          class="m-fat-card__action--eye mr-1"
        ></app-circle-icon>
        <app-circle-icon
          v-if="this.link_download !== ''"
          variant="medium"
          type="Hollow"
          icon="icon-download"
          typeButton="true"
          :link="downloadRef"
          class="m-fat-card__action--download mr-1"
        ></app-circle-icon>
        <app-circle-icon
          v-if="this.link_share !== ''"
          variant="medium"
          type="Hollow"
          icon="icon-share"
          typeButton="true"
          :link="link_share"
          class="m-fat-card__action--share mr-1"
        ></app-circle-icon>
      </div>

      <div class="m-fat-card__action" v-if="this.typeLine === true && (!this.isPay || this.showDownload)">
        <div class="m-fat-card__action--download">
          <button @click="downloadRef()" v-if="this.link_download !== ''">
            <span class="icon-download text-primary"></span>
          </button>
        </div>

        <div class="m-fat-card__action--eye" v-if="false">
          <button
            :href="[link_eye]"
            v-if="this.link_eye !== ''"
            @click="viewRef()"
          >
            <span class="icon-eye text-primary"></span>
          </button>
        </div>

        <div class="m-fat-card__action--share" v-show="this.isActive && !this.isPay" v-if="false">

          <b-form-checkbox
              :id="'checkbox_'+name"
              v-model="object.selected"

              :name="'checkbox_'+name"
              value="true"
              unchecked-value="false"

              class="mt-3"
          ></b-form-checkbox>

          <div class="col-xl-6 offset-xl-3 col-10 offset-1 text-center" v-if="false">
            <b-button variant="primary"
                      class="text-uppercase"
                      @click="pagaFattura()">
            {{ $t("generic.paga") }}
            </b-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppCircleIcon from "./AppCircleIcon.vue";
export default {
  name: "AppFatCard",
  components: { AppCircleIcon },

  props: {
    link_download: {
      default: "",
    },
    link_share: {
      default: "",
    },
    link_eye: {
      default: "",
    },
    labelType: {
      default: "FT",
    },
    name: {
      default: "XXXX",
    },

    typeLine: {
      default: false,
    },
    paying: {
      default: false,
    },
    isActive: {
      default: false,
    },
    tipoFile:{
      default:"PDF",
    },

    data_fattura: {
      default: "20/06/2020",
    },
    isPay:{
      default:false
    },
    showDownload:{
      default:false
    },
    importo:{
      default:0
    },
    object:
        {
          default:null
        }
  },
  data() {
    return {
      card: this.card,
    };
  },
  computed: {
    dataFormatted: function()
    {
      let ff=this.data_fattura.substr(0,10);
          return ff;
    },
    tipoDocumento:function () {

        switch(this.labelType)
        {
          case "FT":
              return "Fattura";

          default:
            return "Nota di Credito";
        }

    }
  },

  mounted() {},
  methods: {
    changeElement() {
      this.card = !this.card;
    },
    viewRef() {
      this.$emit("viewRefert",{});
    },
    shareRef() {
      this.$emit("shareRefert",{});
    },
    downloadRef() {
      this.$emit("openRefert",{});
    },
    pagaFattura(){
      this.$emit('pagaFattura', {});
    }
  },
};
</script>
