<template>
  <div class="registrazione">
    <div class="row no-gutters">
      <div class="col-10 offset-1">
        <app-fat-head @change-type="updateRow" ></app-fat-head>
        <div class="row no-gutters">
          <div
            class="col-12 offset-0"
            v-bind:class="{
              'col-lg-10 offset-lg-1 col-xl-10 offset-xl-1': !this.row,
            }"
          >

            <app-fat-list v-if="this.fatture.length>0" :selectTypeLine="row" :fatture="this.fatture" ></app-fat-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppFatHead from "../components/organisms/AppFatHead.vue";
import AppFatList from "../components/organisms/AppFatList.vue";
import apiAxios from "@/libs/apiAxios";
export default {
  components: { AppFatHead, AppFatList },
  name: "fatture",

  created() {
    this.getDataFromApi();
  },
  data() {
    return {
      loading:true,
      in_fatture:[],
      registration: {
        firstName: "Giacomo",
        lastName: "Magnani",
        codiceFiscale: "mgngcm90c29c618x",
        straniero: false,
        birthDate: "1990-03-29T00:00:00+00:00",
        gender: "M",
        country: "Brescia",
        nationBorn: "Italia",
        cityBornFromTaxCode: "CHIARI",
        username: "mgngcm90c29c618x",
        email: "jack.magnakoki@gmail.com",
        tel: "3293978528",
        invoice: {
          data: {
            administrative: "Lombardia",
            city: "Palazzolo sull'Oglio",
            country: "Italia",
            countryCode: "it",
            county: "Brescia",
            name: "Via Luigi Pirandello 27",
            postcode: "25036",
            value:
              "Via Luigi Pirandello 27, Palazzolo sull'Oglio, Lombardia, Italia",
          },
        },
      },

      row: true,

      selected: "a",
      options: [
  /*      { value: "a", text: "Giacomo Magnani" },
        { value: "b", text: "Mario Rossi" },*/
      ],
    };
  },

  computed: {
    fatture(){
      return this.in_fatture;
    }

  },

  methods: {

    updateRow(e) {
      this.row = e;
    },
    getDataFromApi() {
      this.loading = true
      apiAxios.get('/fatture')
          .then(response => {
            this.loading = false

            this.in_fatture = response.data.ElencoFattureResult;

            console.log(this.fatture);

          })
          .catch(error => {
            this.loading = false
            console.log(error)
          })
    }
  }

};
 /*   ,
    updateRow(e) {
      this.row = e;
    },*/


</script>
